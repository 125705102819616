const getDeviceType = () => {
  const ua = navigator.userAgent;
  if (/iPad/.test(ua)) {
    return 'pad';
  }
  if (/Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Silk/.test(ua)) {
    return 'phone';
  }
  return 'pc';
};

export default getDeviceType;
