import { BIRTHDAY_FORMAT } from '../shop/constants/user';

/** Start of SignUp Settings */
export const SIGNUP_METHOD = {
  EMAIL: 'email',
  MOBILE: 'mobile',
  EMAIL_AND_MOBILE: 'email_and_mobile',
};

// Some 'status' of settings are boolean strings
export const BOOLEAN_STATUS = {
  TRUE: 'true',
  FALSE: 'false',
};

export const ALL_SUPPORTED_COUNTRIES_STRING = '*';

export const SUPPORTED_COUNTRIES_ALL = [ALL_SUPPORTED_COUNTRIES_STRING];
/** End of SignUp Settings */

/** Start of Birthday Settings */
export const DEFAULT_MINIMUM_AGE_LIMIT = '13';
/** End of Birthday Settings */

export const GENERAL_FIELD_TYPE = {
  GENDER: 'gender',
  BIRTHDAY: 'birthday',
};

export const DEFAULT_GENERAL_FIELDS = [
  {
    type: GENERAL_FIELD_TYPE.GENDER,
    options: {
      order: {
        include: BOOLEAN_STATUS.FALSE,
        required: BOOLEAN_STATUS.FALSE,
      },
      signup: {
        include: BOOLEAN_STATUS.FALSE,
        required: BOOLEAN_STATUS.FALSE,
      },
      profile: {
        include: BOOLEAN_STATUS.FALSE,
        required: BOOLEAN_STATUS.FALSE,
      },
    },
  },
  {
    type: GENERAL_FIELD_TYPE.BIRTHDAY,
    options: {
      order: {
        include: BOOLEAN_STATUS.FALSE,
        required: BOOLEAN_STATUS.FALSE,
      },
      signup: {
        include: BOOLEAN_STATUS.FALSE,
        required: BOOLEAN_STATUS.FALSE,
      },
      profile: {
        include: BOOLEAN_STATUS.FALSE,
        required: BOOLEAN_STATUS.FALSE,
      },
    },
  },
];

// Fit API Response
export const DEFAULT_USER_SETTING = {
  signup_method: SIGNUP_METHOD.EMAIL,
  email_verification: {
    status: BOOLEAN_STATUS.FALSE,
  },
  login_with_verification: {
    status: BOOLEAN_STATUS.FALSE,
  },
  sms_verification: {
    status: BOOLEAN_STATUS.FALSE,
    supported_countries: SUPPORTED_COUNTRIES_ALL,
  },
  minimum_age_limit: DEFAULT_MINIMUM_AGE_LIMIT,
  birthday_format: BIRTHDAY_FORMAT.YYYYMMDD,
  general_fields: DEFAULT_GENERAL_FIELDS,
  custom_fields: [],
  enable_age_policy: false,
};
