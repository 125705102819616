import {
  has,
  isPlainObject,
  cloneDeep,
  isString,
  flow,
  merge,
  curryRight,
  keyBy,
  omit,
} from 'lodash-es';
import {
  DEFAULT_USER_SETTING,
  BOOLEAN_STATUS,
  DEFAULT_MINIMUM_AGE_LIMIT,
} from '../constants/userSetting';
import isNumeric from './isNumeric';

export const wrapper = curryRight(
  (userSetting = {}, fieldName, options = {}, callback) =>
    has(userSetting, fieldName)
      ? callback(userSetting, fieldName, options)
      : userSetting,
  4,
);

export const parseBooleanStatus = wrapper({}, (userSetting, fieldName) => {
  if (isPlainObject(userSetting?.[fieldName])) {
    const { status } = userSetting[fieldName];
    userSetting[fieldName].status = status === BOOLEAN_STATUS.TRUE;
  }
  return userSetting;
});

export const parseStringToNumber = wrapper(
  (userSetting, fieldName, { defaultValue = 0 }) => {
    let value = userSetting?.[fieldName];
    if (!isNumeric(value)) {
      userSetting[fieldName] = defaultValue;
    } else if (isString(value)) {
      userSetting[fieldName] = Number(value);
    }
    return userSetting;
  },
);

/**
 * Protect the following fields from unexpected empty string value:
 * - email_verification
 * - login_with_verification
 * The other plain object fields are also protected like sms_verification
 *
 * TODO:
 * After fix the user_setting data issues:
 * - data migration
 * - code of data migration
 * - code of initialization
 * - code of data CRUD
 * This protection can be considered to be removed
 */
export const ensurePlainObjectFields = curryRight(
  (userSetting) =>
    Object.entries(DEFAULT_USER_SETTING).reduce(
      (userSetting, [fieldName, defaultValue]) => {
        if (
          isPlainObject(defaultValue) &&
          has(userSetting, fieldName) &&
          !isPlainObject(userSetting?.[fieldName])
        ) {
          userSetting[fieldName] = cloneDeep(defaultValue);
        }
        return userSetting;
      },
      userSetting,
    ),
  1,
);

export const mergeDefaultExceptFields = curryRight(
  (userSetting, { ignoredFields = [] }) =>
    /** Keep the ignored input fields for next process */
    merge({}, omit(DEFAULT_USER_SETTING, ignoredFields), userSetting),
  2,
);

export const mergeDefaultGeneralFields = (userSetting) => {
  const fieldName = 'general_fields';
  const field = 'type';
  const userSettingMapping = keyBy(userSetting[fieldName], field);
  userSetting[fieldName] = DEFAULT_USER_SETTING?.[fieldName].map((item) =>
    merge({}, item, userSettingMapping?.[item?.[field]]),
  );
  return userSetting;
};

export const setDefaultValues = flow(
  (userSetting) => userSetting || {},
  mergeDefaultExceptFields({ ignoredFields: ['general_fields'] }),
  mergeDefaultGeneralFields,
  ensurePlainObjectFields,
);

export const convertServerValues = flow(
  parseBooleanStatus('email_verification'),
  parseBooleanStatus('login_with_verification'),
  parseBooleanStatus('sms_verification'),
  parseStringToNumber('minimum_age_limit', {
    defaultValue: Number(DEFAULT_MINIMUM_AGE_LIMIT),
  }),
);

export const parseUserSetting = flow(setDefaultValues, convertServerValues);
