import { Payment } from '@payment/payment-sdk';
import axios from 'axios';
import getDeviceType from '../utils/getDeviceType';

export const SHOPLINE_PAYMENT_CLIENT_ID = 'EC1.0';

export const paymentMethodMap = {
  credit: 'CreditCard',
  atm_virtual_account: 'VirtualAccount',
  fpx: 'FPX',
  atome: 'AtomeBNPL',
  chailease: 'ChaileaseBNPL',
  alipay: getDeviceType() === 'phone' ? 'AlipayWap' : 'AlipayQrCode',
  unionpay: 'Unionpay',
  applepay: 'ApplePay',
  jkopay: 'JKOPay',
  wechatpay: 'WeChatPay',
  googlepay: 'GooglePay',
  fps: 'FPS',
  aftee: 'AfteeBNPL',
};

export const SHOPLINE_PAYMENT_SUBTYPE = {
  CREDIT: 'credit',
  FPX: 'fpx',
  ATOME: 'atome',
};

export const getCountry = (countryCode) => {
  const countryList = [
    'zh-cn',
    'zh-hant',
    'en',
    'de',
    'ru',
    'fr',
    'es',
    'ja',
    'th',
  ];
  const targetCountry = countryList.find(function (country) {
    return countryCode === country;
  });
  if (targetCountry) {
    if (targetCountry === 'zh-hant') {
      return 'zh-TW';
    } else if (targetCountry === 'zh-cn') {
      return 'zh-CN';
    }

    return targetCountry;
  }
  return 'en';
};

export const getSLpaymentService = (ajaxPackage) => {
  return {
    getDefaultPaymentConfig: (paymentMethod) => ({
      clientId: SHOPLINE_PAYMENT_CLIENT_ID,
      paymentMethod:
        paymentMethodMap[
          paymentMethod?.config_data?.shopline_payment_payment
        ] || 'CreditCard',
      merchantId: window.mainConfig.merchantData.sl_payment_merchant_id,
      channelId: 'SLPayments',
      countryCode: window.mainConfig.merchantData.base_country_code,
      currency: window.mainConfig.merchantData.base_currency_code,
      language: getCountry(window.mainConfig.localeData.loadedLanguage.code),
      installmentCounts: paymentMethod?.config_data?.installments || [],
      env: window.mainConfig.shoplinePaymentV2Env,
      customerToken: '',
      paymentInstrument: {
        bindCard: {
          enable: false,
          protocol: {
            mustAccept: false,
            textType: {
              paymentAgreement: false,
            },
            defaultSwitchStatus: false,
            switchVisible: false,
          },
        },
      },
      eventTrackingConfig: {
        url: 'https://events.shoplytics.com/api/v1/slp',
        mode: 'ajax',
        method: 'post',
      },
    }),
    renderSDK: ({ paymentConfig, elementId }) => {
      return new Payment(paymentConfig, elementId).init();
    },
    getPaymentInstruments: () => {
      return ajaxPackage.get('/api/shopline_payment/payment_instruments');
    },
    updatePaymentInstruments: (payload) => {
      return ajaxPackage.post(
        '/api/shopline_payment/payment_instruments',
        payload,
      );
    },
    deletePaymentInstruments: (id) => {
      return ajaxPackage.delete(
        `/api/shopline_payment/payment_instruments/${id}`,
      );
    },
    getCustomerToken: () => {
      return ajaxPackage.get('/api/shopline_payment/customer_token');
    },
  };
};

export default getSLpaymentService(axios);
