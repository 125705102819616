import { isNil } from 'lodash-es';

/**
 * Check the string is the valid number or not
 * @param {any} value
 * @returns {boolean}
 */
export const isNumeric = (value) =>
  !isNil(value) && !Number.isNaN(parseFloat(value));

export default isNumeric;
